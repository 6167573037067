<div class="container w-50" *ngIf="!showSpinner">
  <div class="row">
    <div class="col">
      <div class="alert alert-success" role="alert" *ngIf="showSuccess">
        Categoría creada con éxito!
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <form class="w-50" [formGroup]="categoryGroup">
        <label for="exampleInputEmail1">Nombre</label>
        <input
          type="text"
          class="form-control"
          id="exampleInputEmail1"
          placeholder="Nombre de la categoría"
          formControlName="category"
        />
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <button class="btn btn-light cursor-pointer m-3" (click)="newCategory()">
        Crear categoría
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <table class="table table-bordered">
        <thead class="thead-light">
          <tr>
            <th scope="col">Categorías</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let category of categoriesList">
            <td>
              <div class="d-flex row">
                <div class="col mt-2">
                  <span>{{ category.category }}</span>
                </div>
                <div class="col d-flex justify-content-end">
                  <button
                    class="btn btn-light"
                    (click)="route(category.category)"
                  >
                    ...
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<app-spinner *ngIf="showSpinner"></app-spinner>
