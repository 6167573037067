import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CampaignService } from 'src/app/services/campaign.service';

@Component({
  selector: 'app-admin-campaign',
  templateUrl: './admin-campaign.component.html',
  styleUrls: ['./admin-campaign.component.scss'],
})
export class AdminCampaignComponent implements OnInit {
  showSpinner: boolean = true;
  imagesList: any = [];
  bannersList: any[] = [];
  fileToUpload: File = null;
  showNewBanner: boolean = false;
  publicidadList: any = [];
  headerList: any = [];
  cuponsList: any = [];
  campaignGroup: FormGroup;
  cuponGroup: FormGroup;
  showSuccess: boolean = false;
  campaignGot: any = {};
  constructor(
    private service: CampaignService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {
    this.campaignGroup = formBuilder.group({
      paragraph: [''],
    });
    this.cuponGroup = formBuilder.group({
      active: [''],
      amount: [''],
      conditions: [''],
      description: [''],
      key: [''],
      kind: [''],
    });
    this.chargeCampaign();
  }

  ngOnInit(): void {}

  chargeCampaign() {
    this.service.getCampaign().subscribe((res) => {
      this.showSpinner = false;
      this.campaignGot = res['body']['body'];
      if (res['body']['body']['banner'])
        this.bannersList = res['body']['body']['banner'];
      if (res['body']['body']['header'])
        this.headerList = res['body']['body']['header'];
      if (res['body']['body']['publicidad'])
        this.publicidadList = res['body']['body']['publicidad'];
      if (res['body']['body']['cupons'])
        this.cuponsList = res['body']['body']['cupons'];
    });
  }

  onFileChanges(file, kind) {
    this.imagesList.push(file[0]);
  }

  bannerActiveChange(image, status) {
    this.bannersList = this.bannersList.map((img) => {
      if (img['url'] === image['url'] && status.target.checked)
        return {
          url: img.url,
          active: status.target.checked,
          mobile: img.mobile
        };
      if (img['url'] === image['url'] && !status.target.checked)
        return {
          url: img.url,
          active: status.target.checked,
          mobile: img.mobile
        };
      return img;
    });
    this.save('banner');

    
  }

  mobileVersionChange(image, status) {
    this.bannersList = this.bannersList.map((img) => {
      if (img['url'] === image['url'] && status.target.checked)
        return {
          url: img.url,
          active: img.active,
          mobile: status.target.checked,
        };
      if (img['url'] === image['url'] && !status.target.checked)
          return {
            url: img.url,
            active: img.active,
            mobile: status.target.checked,
          };
      return img;
    });
    this.save('banner');
  }

  publicidadActiveChange(image, status) {
    this.publicidadList = this.publicidadList.map((img) => {
      if (img['url'] === image['url'] && status.target.checked)
        return {
          url: img.url,
          active: status.target.checked,
        };
      if (img['url'] === image['url'] && !status.target.checked)
        return {
          url: img.url,
          active: status.target.checked,
        };
      return img;
    });
    this.save('publicidad');
  }

  save(kind) {
    this.showSpinner = true;
    let item = {};
    if (kind === 'banner') {
      item = {
        isBanner: true,
        images: [...this.bannersList, ...this.imagesList],
      };
    } else if (kind === 'publicidad') {
      item = {
        isPublicidad: true,
        images: [...this.publicidadList, ...this.imagesList],
      };
    } else if (kind === 'header') {
      item = {
        isHeader: true,
        publicidad: [...this.headerList],
      };
    } else if (kind === 'cupons') {
      item = {
        isCupon: true,
        cupons: [...this.cuponsList],
      };
    }
    this.service.postCampaign(item).subscribe((res) => {
      this.showSuccess = res['body']['success'];
      this.chargeCampaign();
      this.showSpinner = false;
      this.imagesList = [];
      this.hideSuccess();
    });
  }

  addHeader() {
    this.headerList.push({
      active: true,
      paragraph: this.campaignGroup.controls.paragraph.value,
    });
    this.save('header');
  }

  removeHeader(header) {
    this.headerList = this.headerList.filter(
      (hdr) => hdr.paragraph !== header.paragraph
    );
    this.save('header');
  }

  headerActiveChange(header, status) {
    this.headerList = this.headerList.map((hdr) => {
      if (hdr['paragraph'] === header['paragraph'] && status.target.checked)
        return {
          paragraph: hdr['paragraph'],
          active: status.target.checked,
        };
      if (hdr['paragraph'] === header['paragraph'] && !status.target.checked)
        return {
          paragraph: hdr['paragraph'],
          active: status.target.checked,
        };
      return hdr;
    });
    this.save('header');
  }

  hideSuccess() {
    setTimeout(() => {
      this.showSuccess = false;
    }, 3000);
  }

  addCupon() {
    this.cuponsList.push({
      active: false,
      description: this.cuponGroup.controls.description.value,
    });
    this.save('cupons');
  }

  removeCupon(cupon) {
    this.cuponsList = this.cuponsList.filter(
      (hdr) => hdr.description !== cupon.description
    );
    this.save('cupons');
  }

  cuponActiveChange(cupon, status) {
    this.cuponsList = this.cuponsList.map((hdr) => {
      if (hdr['description'] === cupon['description'] && status.target.checked)
        return {
          ...hdr,
          active: status.target.checked,
        };
      if (hdr['description'] === cupon['description'] && !status.target.checked)
        return {
          ...hdr,
          active: status.target.checked,
        };
      return hdr;
    });
    this.save('cupons');
  }

  route(cupon) {
    this.router.navigate(['dashboard/administrar', cupon, 'edit']);
  }
}
