import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoriesService } from 'src/app/services/categories.service';

@Component({
  selector: 'app-edit-subcategory',
  templateUrl: './edit-subcategory.component.html',
  styleUrls: ['./edit-subcategory.component.scss'],
})
export class EditSubcategoryComponent implements OnInit {
  showSpinner: boolean = true;
  categoryGot: any = {};
  bannersList: any[] = [];
  clasificacionList: any[] = [];
  seasonList: any[] = [];
  showNewSeason: boolean = false;
  showNewBanner: boolean = false;
  categoryGroup: FormGroup;
  clasificacionGroup: FormGroup;
  fileToUpload: File = null;
  showSuccess: boolean = false;
  imagesList: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private service: CategoriesService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.categoryGroup = formBuilder.group({
      category: [''],
      hiden: [''],
      newSeason: [''],
    });

    this.clasificacionGroup = formBuilder.group({
      clasificacion: [''],
    });
    this.chargeCategory();
  }

  ngOnInit(): void {}

  chargeCategory() {
    this.service.getCategory().subscribe((res) => {
      this.showSpinner = false;
      const category = this.route.snapshot.paramMap.get('categoria');
      const subcategory = this.route.snapshot.paramMap.get('subcategoria');
      const categoryGot = res['body']['body'].find(
        (cateogryItem) => cateogryItem['category'] === category
      );


      console.log(this.categoryGot)
      this.categoryGot = categoryGot['sub-categories'].find(
        (item) => item.category === subcategory
      );
      console.log(this.categoryGot);
      if (this.categoryGot['images'])
        this.bannersList = this.categoryGot['images'];

      if (this.categoryGot['season'])
        this.seasonList = this.categoryGot['season'];

      if (this.categoryGot['clasification'])
        this.clasificacionList = this.categoryGot['clasification'];
      this.initForm(this.categoryGot);
    });
  }

  initForm(category) {
    this.categoryGroup = this.formBuilder.group({
      category: [category.category],
      hiden: [!category.hiden],
      newSeason: [''],
    });
  }

  deleteSeason(season) {
    this.seasonList = this.seasonList.filter((seasonL) => seasonL !== season);
  }

  addSeason() {
    this.showNewSeason = true;
  }

  saveSeason() {
    if (this.categoryGroup.controls.newSeason.value !== '') {
      this.seasonList.push(this.categoryGroup.controls.newSeason.value);
      this.seasonList = [...new Set(this.seasonList)];
    }
    this.showNewSeason = false;
  }
  addBanner() {
    this.showNewBanner = true;
  }
  onFileChanges(file) {
    this.imagesList.push(file[0]);
  }

  bannerActiveChange(image, status) {
    this.bannersList = this.bannersList.map((img) => {
      if (img['url'] === image['url'] && status.target.checked)
        return {
          url: img.url,
          active: status.target.checked,
        };
      if (img['url'] === image['url'] && !status.target.checked)
        return {
          url: img.url,
          active: status.target.checked,
        };
      return img;
    });
    this.save();
  }

  hideSuccess() {
    setTimeout(() => {
      this.showSuccess = false;
    }, 3000);
  }

  addClasification() {
    this.clasificacionList.push(
      this.clasificacionGroup.controls['clasificacion'].value
    );
    this.save();
  }

  deleteClasification(clasification) {
    this.clasificacionList = this.clasificacionList.filter((item) => item !== clasification);
    this.save();
  }

  save() {
    this.showSpinner = true;
    const categoryToSave = {
      isCategory: false,
      hiden: !this.categoryGroup.controls.hiden.value,
      isNew: false,
      season: this.seasonList,
      subcategory: this.categoryGot.category,      
      category: this.route.snapshot.paramMap.get('categoria'),
      clasification: this.clasificacionList,
      images: [...this.bannersList, ...this.imagesList],
    };
    this.service.postCategory(categoryToSave).subscribe((res) => {
      this.showSuccess = res['body']['success'];
      this.chargeCategory();
      this.showSpinner = false;
      this.imagesList = [];
      this.hideSuccess();
    });
  }
}