<div class="container" *ngIf="!showSpinner">
  <div class="row">
    <div class="col">
      <div
        *ngIf="success"
        class="alert alert-success messageDistribution"
        role="alert"
      >
        {{ message }}
      </div>
      <div
        *ngIf="showAlert"
        class="alert alert-Danger messageDistribution"
        role="alert"
      >
        {{ message }}
      </div>
      <div class="custom-control custom-switch">
        <input
          type="checkbox"
          class="custom-control-input"
          id="customSwitches"
          [disabled]="disableToggle"
          [checked]="productSelected?.status == 'Activo'"
          (change)="changeProductStatus()"
        />
        <label class="custom-control-label" for="customSwitches">{{
          productSelected?.status
        }}</label>
      </div>
      <small class="form-text text-muted"
        >*Cambia el estado de los productos sin tener que llenar el formulario,
        ni guardar</small
      >
      <form [formGroup]="product" class="form-group" (ngSubmit)="onSubmit()">
        <div class="marginTo">
          <label for="category"> Categoría </label>
          <select
            id="category"
            class="form-control"
            formControlName="category"
            id="exampleFormControlSelect1"
            (change)="onCategorySelected($event)"
          >
            <option *ngFor="let category of categoryList">
              {{ category["category"] }}
            </option>
          </select>
          <small id="nameHelp" class="form-text text-muted"
            >*Esta sera la Categoría del producto</small
          >
        </div>
        <div class="marginTo" >
          <label for="category"> Subcategoría </label>
          <select
            id="category"
            class="form-control"
            formControlName="subcategory"
            id="exampleFormControlSelect1"
            (change)="onSubategorySelected($event)"
          >
            <option *ngFor="let subCategory of subCategoryList">
              {{ subCategory.category }}
            </option>
          </select>
          <small id="nameHelp" class="form-text text-muted"
            >*Esta sera la subcategoría del producto</small
          >
        </div>

        <div class="marginTo" *ngIf="clasificationList.length > 0">
          <label for="category"> Clasificación </label>
          <select
            id="category"
            class="form-control"
            formControlName="subcategory"
            id="exampleFormControlSelect1"
          >
            <option *ngFor="let clasification of clasificationList">
              {{ clasification }}
            </option>
          </select>
          <small id="nameHelp" class="form-text text-muted"
            >*Acá podrás darle al producto una clasificación más
            detallada</small
          >
        </div>

        <div class="marginTo">
          <label for="description"> Descripción </label>
          <input
            id="description"
            type="text"
            formControlName="description"
            class="form-control"
          />
          <small id="descriptionHelp" class="form-text text-muted"
            >*Escriba el nombre del producto, esta será la mostrada como
            título</small
          >
        </div>
        <div class="marginTo">
          <label for="description1"> Detalles </label>      
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" formControlName="details"  id="description1"></textarea>
          <small id="descriptionHelp" class="form-text text-muted"
            >*Escriba características importantes del producto</small
          >
        </div>
        <div class="marginTo">
          <label for="fakePrice"> Precio (COP) </label>
          <input
            id="fakePrice"
            type="number"
            formControlName="fakePrice"
            class="form-control"
          />
          <small
            *ngIf="product.value['fakePrice'] < 0"
            id="nameprice"
            class="form-text text-danger"
            >*Valor erroneo</small
          >
        </div>
        <div class="marginTo">
          <a
            data-toggle="collapse"
            href="#multiCollapseExample1"
            aria-expanded="false"
            aria-controls="multiCollapseExample1"
          >
            Agregar oferta
          </a>
          <small id="descriptionHelp" class="form-text text-muted"
            >*Agregue alguna promoción en procentaje de descuento (%)</small
          >
          <div class="row">
            <div class="col">
              <div class="collapse multi-collapse" id="multiCollapseExample1">
                <div class="card card-body">
                  <input
                    id="deal"
                    type="number"
                    min="0"
                    max="100"
                    formControlName="deal"
                    class="form-control"
                  />
                  <small
                    *ngIf="
                      product.value['deal'] < 0 || product.value['deal'] > 100
                    "
                    id="nameprice"
                    class="form-text text-danger"
                    >*Valor erroneo</small
                  >
                  <div class="marginTo">
                    <label for="fakePrice"> Precio con descuento </label>
                    <br />
                    <label for="fakePrice" class="font-weight-bold">
                      {{ product.value["price"] | currency : "COP" }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="marginTo" *ngIf="!isEdit">
          <label for="reference"> Referencia del producto </label>
          <div class="form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="exampleCheck1"
              (change)="generateReference()"
              [disabled]="isEdit"
            />
            <label class="form-text text-muted" for="exampleCheck1"
              >Generar referencia</label
            >
          </div>
          <input
            id="reference"
            type="text"
            formControlName="reference"
            class="form-control"
          />
          <small id="dealreference" class="form-text text-muted"
            >*Indique una referencia al producto</small
          >
        </div>
        <div class="marginTo">
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              id="customFileLang"
              lang="es"
              alife-file-to-base64
              multiple
              (onFileChanged)="onFileChanges($event)"
              accept="image/*"
            />
            <label class="custom-file-label" for="customFile"
              >Cargar fotos</label
            >
          </div>
          <div>
            <ul class="list-group">
              <div class="card-columns">
                <div
                  class="card mt-5"
                  style="margin: 10px"
                  *ngFor="let image of product.value['image']; let i = index"
                >
                  <img src="{{ image }}" class="card-img-top" alt="..." />
                  <div class="card-body">
                    <button class="btn btn-light" (click)="deleteImage(image)">
                      Eliminar
                    </button>
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </div>
        <div class="marginTo displayFlexColumn">
          <button class="btn btn-light" type="button" (click)="resetForm()">
            Limpiar
          </button>
          <button
            class="btn btn-light mt-3"
            type="submit"
          >
            Guardar
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<app-spinner *ngIf="showSpinner"></app-spinner>
