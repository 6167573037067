import { Injectable } from '@angular/core';
import { environment} from '../../environments/environment'
import {SignInInformation}from '../model/model.model'
import { Amplify , Auth } from 'aws-amplify';
import { Router } from '@angular/router';
Amplify.configure(environment.amplify)


@Injectable({
  providedIn: 'root'
})
export class SignInService {

  constructor(private router:Router) { }


  public signInAmplify(signInInformation:SignInInformation){ 
    return Auth.signIn(signInInformation.username,signInInformation.password)
  }

  public completeNewPasswordAmplify(user,signInInformation:SignInInformation){    
    return Auth.completeNewPassword(user,signInInformation.password,{})   
  }

  public isAuthenticated(){
    return Auth.currentAuthenticatedUser()
    .then(res=>{
      return true})
    .catch(err=>{
      this.router.navigate(['login']);
      return false})
  }
}
