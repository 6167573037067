<div class="container w-100">
  <div class="row" *ngIf="!showSpinner">
    <div class="col ">
        <table class="table table-bordered ">
            <thead>
              <tr class="table-secondary">
                <th scope="col">Referencia</th>
                <th scope="col">Fecha de pago</th>
                <th scope="col">Pago</th>
                <th scope="col">Nombre</th>
                <th scope="col">Apellido</th>
                <th scope="col">Teléfono</th>
              </tr>
            </thead>
            <tbody>
              <tr  *ngFor = "let sales of salesArray">
                <th scope="row">{{sales.paymentReference}}</th>
                <td>{{sales.transactionDate | date:'dd-MM-yyyy'}}</td>
                <td class="table-warning" *ngIf="sales.status === 'PENDING'" >{{sales.status}}</td>
                <td class="table-danger" *ngIf="sales.status === 'DECLINED'" >{{sales.status}}</td>
                <td class="table-success" *ngIf="sales.status === 'APPROVED'" >{{sales.status}}</td>
                <td>{{sales.name}}</td>
                <td>{{sales.lastName}}</td>
                <td>{{sales.cellphone}}</td>
                <td> <div class="col d-flex justify-content-end">
                    <button class="btn btn-light" (click)="redirect(sales.paymentReference)">Detalle</button>
                  </div></td>
              </tr>   
                   
            </tbody>
          </table>
    </div>
  </div>
</div>
<app-spinner *ngIf="showSpinner"></app-spinner>
