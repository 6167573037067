<div class="container w-100" *ngIf="!showSpinner">
  <div class="row">
    <div class="col">
      <div class="alert alert-success" role="alert" *ngIf="showSuccess">
        Cupón modificado con éxito!
      </div>
    </div>
  </div>
  <form  [formGroup]="cuponGroup">
    <div class="row">
      <div class="col">
        <label for="exampleInputEmail1">Nombre</label>
        <input
          type="text"
          class="form-control"
          id="exampleInputEmail1"
          placeholder="Nombre o descripción del cupón"
          formControlName="description"
          disabled
        />
      </div>
    </div>
    <div class="col d-flex justify-content-end mt-4">
      <div class="form-check">
        <input
          type="checkbox"
          class="form-check-input"
          id="exampleCheck1"
          formControlName="active"
        />
        <label class="form-check-label" for="exampleCheck1">Activo</label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label for="exampleInputEmail1">Llave</label>
        <input
          type="text"
          class="form-control"
          id="exampleInputEmail1"
          placeholder="Nombre o descripción del cupón"
          formControlName="key"
        />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <label for="exampleInputEmail1">%</label>
        <input
          type="number"
          class="form-control"
          id="exampleInputEmail1"
          placeholder="Nombre o descripción del cupón"
          formControlName="amount"
        />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <div class="form-group">
          <label for="inputState">Tipo de cupón</label>
          <select id="inputState" class="form-control" formControlName="kind">
            <option selected>automático</option>
            <option>manual</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <div class="form-group">
          <label for="inputState">Regla de distribución</label>
          <select id="inputState" class="form-control" formControlName="conditions">
            <option selected>Primera compra</option>
          </select>
        </div>
      </div>
    </div>    
    <div class="row mt-5">
      <div class="col">
        <button class="btn btn-light cursor-pointer" (click)="save()">
          Guardar
        </button>
      </div>
    </div>
  </form>
</div>
<app-spinner *ngIf="showSpinner"></app-spinner>
