import { Component, OnInit } from '@angular/core';
import { VariablesService } from 'src/app/services/variables.service';
import {productConst} from '../../constants/constants'
@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {

  constructor(private service: VariablesService) { }

  ngOnInit(): void {
  }

  public initForm(){
    this.service.updateShowVariable(false);
    this.service.updateProductVariable(productConst);
  }

}
