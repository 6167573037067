<div class="container" *ngIf="!showSpinner">
  <div class="row">
    <div class="col">
      <div class="alert alert-success" role="alert" *ngIf="showSuccess">
        Categoría modificada con éxito!
      </div>
      <div class="accordion" id="accordionExample">
        <div class="card">
          <div class="card-header" id="headingOne">
            <h5 class="mb-0">
              <button
                class="btn btn-light"
                type="button"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Información general
              </button>
            </h5>
          </div>

          <div
            id="collapseOne"
            class="collapse show"
            aria-labelledby="headingOne"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <form class="w-50" [formGroup]="categoryGroup">
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Nombre</label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        placeholder="Nombre de la categoría"
                        disabled
                        formControlName="category"
                      />
                    </div>
                  </div>
                  <div class="col mt-4 ml-4">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck1"
                        formControlName="hiden"
                      />
                      <label class="form-check-label" for="exampleCheck1"
                        >Visible</label
                      >
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label
                    >Temporada
                    <span
                      class="badge badge-secondary cursor-pointer"
                      (click)="addSeason()"
                      >+</span
                    >
                    <input
                      *ngIf="this.showNewSeason"
                      type="text"
                      class="form-control m-2"
                      id="exampleInputEmail1"
                      placeholder="Nombre de la temporada"
                      formControlName="newSeason"
                    /><span
                      class="badge badge-secondary m-1 cursor-pointer"
                      (click)="saveSeason()"
                      *ngIf="this.showNewSeason"
                      >Agregar</span
                    ></label
                  >
                  <table class="table table-bordered w-50">
                    <tbody>
                      <tr *ngFor="let season of seasonList">
                        <td>
                          <div class="d-flex row">
                            <div class="col mt-2">
                              <span>{{ season }}</span>
                            </div>
                            <div class="col d-flex justify-content-end">
                              <button
                                class="btn btn-light cursor-pointer"
                                (click)="deleteSeason(season)"
                                *ngIf="season !== 'general'"
                              >
                                x
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <button
                    class="btn btn-light cursor-pointer m-3"
                    (click)="save()"
                  >
                    Guardar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingTwo">
            <h5 class="mb-0">
              <button
                class="btn btn-light collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Banner
              </button>
            </h5>
          </div>
          <div
            id="collapseTwo"
            class="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <div>
                <span
                  class="badge badge-secondary cursor-pointer"
                  (click)="addBanner()"
                  >+</span
                >
              </div>
              <div class="custom-file w-25 m-2" *ngIf="this.showNewBanner">
                <input
                  type="file"
                  class="custom-file-input"
                  id="customFileLang"
                  lang="es"
                  alife-file-to-base64
                  multiple
                  (onFileChanged)="onFileChanges($event)"
                  accept="image/*"
                />
                <label class="custom-file-label cursor-pointer" for="customFile"
                  >Cargar fotos</label
                >
                <span
                  class="badge badge-secondary mt-2 cursor-pointer"
                  (click)="save()"
                  >Subir banner</span
                >
              </div>
              <div class="card-columns">
                <div
                  class="card mt-5"
                  style="margin: 10px"
                  *ngFor="let images of this.bannersList"
                >
                  <img src="{{ images.url }}" class="card-img-top" alt="..." />
                  <div class="card-body">
                    <div class="row">
                      <div class="col-6 mt-4 ml-4">
                        <div
                          class="form-check"
                          *ngIf="this.bannersList.length > 1"
                        >
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="exampleCheck1"
                            [checked]="images.active"
                            (change)="mobileVersionChange(images, $event)"
                          />
                          <label class="form-check-label" for="exampleCheck1"
                            >Es versión mobile</label
                          >
                        </div>
                      </div>
                      <div class="col-6 mt-4 ml-4">
                        <div class="form-check">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="exampleCheck1"
                            [checked]="images.mobile"
                            (change)="bannerActiveChange(images, $event)"
                          />
                          <label class="form-check-label" for="exampleCheck1"
                            >Activo</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingTwo">
            <h5 class="mb-0">
              <button
                class="btn btn-light collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                Miniatura
              </button>
            </h5>
          </div>
          <div
            id="collapseFive"
            class="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <div>
                <span
                  class="badge badge-secondary cursor-pointer"
                  (click)="addThumbnail()"
                  >+</span
                >
              </div>
              <div class="custom-file w-25 m-2" *ngIf="this.showNewThumbnail">
                <input
                  type="file"
                  class="custom-file-input"
                  id="customFileLang"
                  lang="es"
                  alife-file-to-base64
                  multiple
                  (onFileChanged)="thumbnailOnFileChanges($event)"
                  accept="image/*"
                />
                <label class="custom-file-label cursor-pointer" for="customFile"
                  >Cargar fotos</label
                >
                <span
                  class="badge badge-secondary mt-2 cursor-pointer"
                  (click)="save()"
                  >Subir miniatura</span
                >
              </div>
              <div class="card-columns">
                <div
                  class="card mt-5"
                  style="margin: 10px"
                  *ngFor="let images of this.thumbnailList"
                >
                  <img src="{{ images.url }}" class="card-img-top" alt="..." />
                  <div class="card-body">
                    <div class="row">
                      <div class="col-6 mt-4 ml-4">
                      </div>
                      <div class="col-6 mt-4 ml-4">
                        <div class="form-check">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="exampleCheck1"
                            [checked]="images.active"
                            (change)="thumbnailActiveChange(images, $event)"
                          />
                          <label class="form-check-label" for="exampleCheck1"
                            >Activo</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingThree">
            <h5 class="mb-0">
              <button
                class="btn btn-light collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Subcategorías
              </button>
            </h5>
          </div>
          <div
            id="collapseThree"
            class="collapse"
            aria-labelledby="headingThree"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <form class="w-50" [formGroup]="subcategoryGroup">
                    <label for="exampleInputEmail1">Nombre</label>
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      placeholder="Nombre de la subcategoría"
                      formControlName="subcategory"
                    />
                  </form>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <button
                    class="btn btn-light cursor-pointer m-3"
                    (click)="newSubCategory()"
                  >
                    Guardar subcategoría
                  </button>
                </div>
              </div>
              <table class="table table-bordered w-50">
                <tbody>
                  <tr *ngFor="let category of this.subCategoriesList">
                    <td>
                      <div class="d-flex row">
                        <div class="col mt-2">
                          <span>{{ category.category }}</span>
                        </div>
                        <div class="col d-flex justify-content-end">
                          <button
                            class="btn btn-light"
                            (click)="redirect(category.category)"
                          >
                            ...
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <button class="btn btn-light cursor-pointer m-3" (click)="save()">
        Guardar
      </button>
    </div>
  </div>
</div>
<app-spinner *ngIf="showSpinner"></app-spinner>
