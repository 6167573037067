<div class="container" *ngIf="!showSpinner">
  <div class="row">
    <div class="col">
      <div class="alert alert-success" role="alert" *ngIf="showSuccess">
        Módulo modificado con éxito!
      </div>
      <div class="accordion" id="accordionExample">
        <div class="card">
          <div class="card-header" id="headingOne">
            <h5 class="mb-0">
              <button
                class="btn btn-light"
                type="button"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Información general
              </button>
            </h5>
          </div>

          <div
            id="collapseOne"
            class="collapse show"
            aria-labelledby="headingOne"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <form class="w-100" [formGroup]="moduleGroup">
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Título</label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        placeholder="Nombre del Módulo"
                        disabled
                        formControlName="title"
                      />
                    </div>
                  </div>
                  <div class="col mt-4 ml-4">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck1"
                        formControlName="active"
                      />
                      <label class="form-check-label" for="exampleCheck1"
                        >Activo</label
                      >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Descripción</label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        placeholder="Descripción del Módulo"
                        formControlName="description"
                      />
                    </div>
                  </div>
                </div>
                <button
                  class="btn btn-light cursor-pointer m-3"
                  (click)="save()"
                >
                  Guardar
                </button>
                <div class="row w-100">
                  <div class="col w-100">
                    <div class="custom-file w-25 m-2">
                      <input
                        type="file"
                        class="custom-file-input"
                        id="customFileLang"
                        lang="es"
                        alife-file-to-base64
                        multiple
                        (onFileChanged)="onFileChanges($event)"
                        accept="image/*"
                      />
                      <label
                        class="custom-file-label cursor-pointer"
                        for="customFile"
                        >Cargar foto</label
                      >
                    </div>
                    <div class="card-columns">
                      <div class="card mt-5" style="margin: 10px">
                        <img
                          src="{{ this.moduleGot.url }}"
                          class="card-img-top"
                          alt="..."
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-spinner *ngIf="showSpinner"></app-spinner>
