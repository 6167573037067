import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { VariablesService } from '../services/variables.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public welcomeMessage:boolean=false;
  public welcomeSubription:Subscription;
  
  constructor(private variableService:VariablesService) {
    this.variableService.show.subscribe(res=>{
      this.welcomeMessage=res;
    })
  }
  ngOnInit(): void {
  }
}
