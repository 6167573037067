import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoriesService } from 'src/app/services/categories.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-edit-categories',
  templateUrl: './edit-categories.component.html',
  styleUrls: ['./edit-categories.component.scss'],
})
export class EditCategoriesComponent implements OnInit {
  showSpinner: boolean = true;
  categoryGot: any = {};
  subCategoriesList: any[] = [];
  bannersList: any[] = [];
  thumbnailBannersList: any[] = [];
  seasonList: any[] = [];
  showNewSeason: boolean = false;
  showNewBanner: boolean = false;
  showNewThumbnail: boolean = false;
  categoryGroup: FormGroup;
  fileToUpload: File = null;
  showSuccess: boolean = false;
  subcategoryGroup: FormGroup;
  imagesList: any = [];
  thumbnailList: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private service: CategoriesService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.categoryGroup = formBuilder.group({
      category: [''],
      hiden: [''],
      newSeason: [''],
    });

    this.subcategoryGroup = formBuilder.group({
      subcategory: [''],
    });
    this.chargeCategory();
  }

  ngOnInit(): void {}

  chargeCategory() {
    this.service.getCategory().subscribe((res) => {
      this.showSpinner = false;
      const category = this.route.snapshot.paramMap.get('categoria');
      this.categoryGot = res['body']['body'].find(
        (cateogryItem) => cateogryItem['category'] === category
      );
      if (this.categoryGot['sub-categories'])
        this.subCategoriesList = this.categoryGot['sub-categories'];

      if (this.categoryGot['images'])
        this.bannersList = this.categoryGot['images'];
      
      if (this.categoryGot['thumbnails'])
        this.thumbnailList = this.categoryGot['thumbnails'];

      if (this.categoryGot['season'])
        this.seasonList = this.categoryGot['season'];
      this.initForm(this.categoryGot);
    });
  }

  initForm(category) {
    this.categoryGroup = this.formBuilder.group({
      category: [category.category],
      hiden: [!category.hiden],
      newSeason: [''],
    });
  }

  deleteSeason(season) {
    this.seasonList = this.seasonList.filter((seasonL) => seasonL !== season);
  }

  addSeason() {
    this.showNewSeason = true;
  }

  saveSeason() {
    if (this.categoryGroup.controls.newSeason.value !== '') {
      this.seasonList.push(this.categoryGroup.controls.newSeason.value);
      this.seasonList = [...new Set(this.seasonList)];
    }
    this.showNewSeason = false;
  }
  addBanner() {
    this.showNewBanner = true;
  }

  addThumbnail() {
    this.showNewThumbnail = true;
  }

  thumbnailOnFileChanges(file) {
    this.thumbnailBannersList.push(file[0]);
  }

  onFileChanges(file) {
    this.imagesList.push(file[0]);
  }

  thumbnailActiveChange(image, status) {
    this.thumbnailList = this.thumbnailList.map((img) => {
      if (img['url'] === image['url'] && status.target.checked)
        return {
          url: img.url,
          active: status.target.checked,
        };
      if (img['url'] === image['url'] && !status.target.checked)
        return {
          url: img.url,
          active: status.target.checked,
        };
      return img;
    });
    this.save();
  }

  bannerActiveChange(image, status) {
    this.bannersList = this.bannersList.map((img) => {
      if (img['url'] === image['url'] && status.target.checked)
        return {
          url: img.url,
          active: status.target.checked,
          mobile: img.mobile,
        };
      if (img['url'] === image['url'] && !status.target.checked)
        return {
          url: img.url,
          active: status.target.checked,
          mobile: img.mobile,
        };
      return img;
    });
    this.save();
  }

  mobileVersionChange(image, status) {
    this.bannersList = this.bannersList.map((img) => {
      if (img['url'] === image['url'] && status.target.checked)
        return {
          url: img.url,
          active: img.active,
          mobile: status.target.checked,
        };
      if (img['url'] === image['url'] && !status.target.checked)
        return {
          url: img.url,
          active: img.active,
          mobile: status.target.checked,
        };
      return img;
    });
    this.save();
  }

  save() {
    this.showSpinner = true;
    const categoryToSave = {
      isCategory: true,
      hiden: !this.categoryGroup.controls.hiden.value,
      isNew: false,
      season: this.seasonList,
      category: this.categoryGot.category,
      images: [...this.bannersList, ...this.imagesList],
      thumbnails: [...this.thumbnailList, ...this.thumbnailBannersList],
    };
    this.service.postCategory(categoryToSave).subscribe((res) => {
      this.showSuccess = res['body']['success'];
      this.chargeCategory();
      this.showSpinner = false;
      this.imagesList = [];
      this.thumbnailBannersList = [];
      this.hideSuccess();
    });
  }

  hideSuccess() {
    setTimeout(() => {
      this.showSuccess = false;
    }, 3000);
  }

  redirect(subcategory) {
    this.router.navigate([
      'dashboard/sub-categorias',
      this.categoryGot.category,
      subcategory,
      'edit',
    ]);
  }

  newSubCategory() {
    this.showSpinner = true;
    let item = {
      isCategory: false,
      hiden: true,
      isNew: true,
      subcategory: this.subcategoryGroup.controls['subcategory'].value,
      category: this.route.snapshot.paramMap.get('categoria'),
    };
    this.service.postCategory(item).subscribe((res) => {
      this.showSuccess = res['body']['success'];
      this.showSpinner = false;
      this.hideSuccess();
      this.chargeCategory();
    });
  }
}
