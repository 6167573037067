export const productConst={
    category: "",
    count: 1,
    deal:{
    active: false,
    offer: ""},
    description: "",
    fakePrice: 0,
    hk: "",
    image: [],
    price: 0,
    sk: "",
    title: "",
  }