<div class= "displayFlexRow BackGroundMainColor mainDivTittle">
    <a href="#">
            <img src="../../assets/logo.png" alt="logo" class="navbar__img">
    </a>        
</div>
<div class ="mainAddProduct">    
    <div class="marginTo divAddProduct">
        <div *ngIf="showAlert" class="alert alert-danger messageDistribution" role="alert">
            {{message}}
        </div>
        <form [formGroup]="signInForm" (ngSubmit)="signIn()" >
            <div class="form-group"  >
            <label for="username">Usuario</label>
            <input  type="text" class="form-control" id="username" formControlName="username" aria-describedby="usernameHelp" placeholder="Ingrese usuario" required> 
            <small *ngIf="signInForm.controls.username.errors && !signInForm.controls.username.pristine"  id="usernameHelp" class="form-text text-muted">*Campo requerido</small>
            </div>
            <div class="form-group">
            <label for="password">Contraseña</label>
            <input type="password" class="form-control" id="password" formControlName="password" placeholder="Contraseña" required>
            <small *ngIf="signInForm.controls.password.errors && !signInForm.controls.password.pristine"  id="usernameHelp" class="form-text text-muted">*contraseña inválida</small>
            </div>
            <button type="submit" class="btn-view"  [disabled]="signInForm.invalid">Ingresar</button>
        </form>
    </div>
</div>

  