
<div class="row">
    <div class="col-6">
      <form class="form-inline" [formGroup]="searchForm"  (ngSubmit)="onSubmit()" >
        <input class="form-control mr-sm-2 input-size" formControlName="search" type="search" aria-label="Search">
        <button class="btn-view my-2 my-sm-0" type="submit" [disabled]="searchForm.status =='INVALID'">Buscar</button>
        <button class="btn-view my-2 my-sm-0" type="button" (click)="getTransactions()" >
            <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-arrow-clockwise" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M3.17 6.706a5 5 0 0 1 7.103-3.16.5.5 0 1 0 .454-.892A6 6 0 1 0 13.455 5.5a.5.5 0 0 0-.91.417 5 5 0 1 1-9.375.789z"/>
              <path fill-rule="evenodd" d="M8.147.146a.5.5 0 0 1 .707 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 1 1-.707-.708L10.293 3 8.147.854a.5.5 0 0 1 0-.708z"/>
            </svg>
        </button>
      </form>
    </div> 
    <div class="col-1">
        <div class="dropdown">
            <button class="btn-view dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Filtrar por estado de pago
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" (click) = "statusFilter('APPROVED')">Aprobado</a>
                <a class="dropdown-item" (click) = "statusFilter('DECLINED')">Rechazado</a>
                <a class="dropdown-item" (click) = "statusFilter('PENDING')">Pendiente</a>
            </div>
        </div> 
    </div>
</div>
<div class="alert alert-warning" role="alert" *ngIf="showMessage">
    {{message}}
</div>
<div class="alert alert-success" role="alert" *ngIf="showsuccess">
    {{message}}
</div>
<div class="card-columns">    
      <div class="card" *ngFor="let sale of salesArray">
        <div class="card-body" >
          <h5 class="card-title alert-warning text-center" *ngIf="(sale.delivaryStatus.S != undefined && sale.delivaryStatus.S=='PENDING') || (sale.delivaryStatus != undefined && sale.delivaryStatus=='PENDING')">Pendiente por despacho</h5>
          <h5 class="card-title alert-success text-center" *ngIf="(sale.delivaryStatus.S != undefined && sale.delivaryStatus.S=='DONE') || (sale.delivaryStatus != undefined && sale.delivaryStatus=='DONE')">Despacho listo</h5>
          <h5 class="card-title alert-danger text-center"  *ngIf="(sale.delivaryStatus.S != undefined && sale.delivaryStatus.S=='DISCARD') || (sale.delivaryStatus != undefined && sale.delivaryStatus=='DISCARD')">Descartado</h5>              
        <div class="container">
        <div class="row">
            <div class="col">
                <small class="card-text text-left font-weight-bold">Referencia</small>                
            </div>
            <div class="col">
                <small class="card-text">{{sale.paymentReference.S || sale.paymentReference }}</small>
            </div>
        </div>            
        <div class="row alert-dark" *ngIf ="(sale.status.S != undefined && sale.status.S =='APPROVED') || (sale.status != undefined && sale.status =='APPROVED') "> 
            <div class="col">
                <small class="card-text text-left font-weight-bold">Pago</small>
            </div>
            <div class="col">
                <small class="card-text alert-success text-center">{{sale.status.S || sale.status}}</small>
            </div>
        </div>
        <div class="row alert-dark" *ngIf ="(sale.status.S != undefined && sale.status.S =='PENDING') || (sale.status != undefined && sale.status =='PENDING')"> 
            <div class="col">
                <small class="card-text text-left font-weight-bold">Pago</small>
            </div>
            <div class="col">
                <small class="card-text alert-warning text-center">{{sale.status.S || sale.status}}</small>
            </div>
        </div>
        <div class="row alert-dark" *ngIf ="(sale.status.S != undefined && sale.status.S =='DECLINED') || (sale.status != undefined && sale.status =='DECLINED')"> 
            <div class="col">
                <small class="card-text text-left  font-weight-bold">Pago</small>
            </div>
            <div class="col">
                <small class="card-text alert-danger text-center">{{sale.status.S || sale.status}}</small>
            </div>
        </div>
        <div class="row "> 
            <div class="col">
                <small class="card-text text-left font-weight-bold">Fecha de pago</small>
            </div>
            <div class="col">
                <small class="card-text text-center">{{sale.transactionDate.S || sale.transactionDate}}</small>
            </div>
        </div>
        <div class="row alert-dark" *ngIf ="(sale.enterprise.S!=undefined && sale.enterprise.S =='') || sale.enterprise!=undefined && sale.enterprise =='' ">
            <div class="col text-left">
                <small class="card-text text-left font-weight-bold">Cliente</small>                
            </div>
            <div class="col">
                <small class="card-text">{{sale.name.S || sale.name}} {{sale.lastName.S || sale.lastName}} </small>
            </div>
        </div>
        <div class="row alert-dark" *ngIf ="(sale.enterprise.S!=undefined && sale.enterprise.S !='' && sale.name.S=='') || (sale.enterprise!=undefined && sale.enterprise !='' && sale.name=='')">
            <div class="col text-left">
                <small class="card-text text-left font-weight-bold">Empresa</small>                
            </div>
            <div class="col">
                <small class="card-text">{{sale.enterprise.S || sale.enterprise}} </small>
            </div>
        </div>             
        <div class="row">
            <div class="col text-left">
                <small class="card-text text-left font-weight-bold">Tipo de documento</small>                
            </div>
            <div class="col">
                <small class="card-text">{{sale.docType.S || sale.docType}} </small>
            </div>
        </div> 
        <div class="row alert-dark">
            <div class="col text-left">
                <small class="card-text text-left font-weight-bold">Documento</small>                
            </div>
            <div class="col">
                <small class="card-text">{{sale.document.N || sale.document}} </small>
            </div>
        </div> 
        <div class="row">
            <div class="col text-left">
                <small class="card-text text-left font-weight-bold">Télefono</small>                
            </div>
            <div class="col">
                <small class="card-text">{{sale.cellphone.N || sale.cellphone}} </small>
            </div>
        </div>            
        <div class="row alert-dark">
            <div class="col text-left">
                <small class="card-text text-left font-weight-bold">Email</small>                
            </div>
            <div class="col">
                <small class="card-text">{{sale.email.S || sale.email}} </small>
            </div>
        </div>            
        <div class="row">
            <div class="col text-left">
                <small class="card-text text-left font-weight-bold">Dirección</small>                
            </div>
            <div class="col">
                <small class="card-text">{{sale.address.S || sale.address}} </small>
            </div>
        </div> 
        <div class="row alert-dark" *ngIf = "sale.additionalAddress.S!=''">
            <div class="col text-left">
                <small class="card-text text-left font-weight-bold">Dirección adicional</small>                
            </div>
            <div class="col">
                <small class="card-text">{{ sale.additionalAddress.S || sale.additionalAddress || ' '}} </small>
            </div>
        </div> 
        <div class="row" >
            <div class="col text-left">
                <small class="card-text text-left font-weight-bold">Departamento</small>                
            </div>
            <div class="col">
                <small class="card-text">{{sale.city.S  || sale.city}} </small>
            </div>
        </div>
        <div class="row alert-dark">
            <div class="col text-left">
                <small class="card-text text-left font-weight-bold">Municipio</small>                
            </div>
            <div class="col">
                <small class="card-text">{{sale.state.S  || sale.state}} </small>
            </div>
        </div> 
        <br>
        <small class="card-text text-left font-weight-bold">Artículos vendidos</small>
        <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col">Referencia</th>
                <th scope="col">Categoría</th>
                <th scope="col">Cantidad</th>
              </tr>
            </thead>
            <tbody *ngIf="sale.items.L!= undefined">
              <tr *ngFor="let item of sale.items.L">
                <td>{{item.M.sk.S}}</td>
                <td>{{item.M.category.S}}</td>
                <td>{{item.M.count.N}}</td>
              </tr>              
            </tbody>
            <tbody *ngIf="sale.items!= undefined && sale.items.L== undefined">
                <tr *ngFor="let item of sale.items">
                  <td>{{item.sk}}</td>
                  <td>{{item.category}}</td>
                  <td>{{item.count}}</td>
                </tr>              
              </tbody>
        </table>
        <br>
        <div class="row alert-dark">
            <div class="col text-left">
                <small class="card-text text-left font-weight-bold">Validar venta</small>                
            </div>
        </div>
        
        <div class="row alert-dark padding">
            <div class="col">
                <button class="btn-view" type="button" (click)="confirmTransaction(sale)">
                    Imprimir
                </button>
            </div>
            <div class="col">
                <div class="dropdown">
                    <button class="btn-view dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Cambiar estado
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" (click) ="modifiyTransactionStatus(sale,'DONE')"       *ngIf ="(sale.status.S != undefined && sale.status.S !='DECLINED' ) || (sale.status != undefined && sale.status !='DECLINED' && sale.status.S == undefined )">Despachado</a>
                    <a class="dropdown-item" (click) ="modifiyTransactionStatus(sale, 'PENDING')"   *ngIf ="(sale.status.S != undefined && sale.status.S !='DECLINED' ) || (sale.status != undefined && sale.status !='DECLINED' && sale.status.S == undefined)">Pendiente</a>
                    <a class="dropdown-item" (click) ="modifiyTransactionStatus(sale, 'DISCARD')"   *ngIf ="(sale.status.S != undefined && sale.status.S =='DECLINED' ) || (sale.status != undefined && sale.status =='DECLINED' && sale.status.S == undefined)">Descartado</a>
                    </div>
                </div>
            </div>            
        </div>      
        </div>                     
        </div>
        
      </div>
  </div>