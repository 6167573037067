import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment'
@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private url = environment.url

  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' ,
     'x-api-key': environment.apikey 
    }),
    observe: 'response' as 'response',
  };
  private model= {
    "count": 1,
    "deal": {
      "active": false,
      "offer": ""
    },
    "description": "",
    "fakePrice": 0,
    "hk": "",
    "image": [],
    "price": 0,
    "sk": "",
    "category":"",
    "status":"",
    "details":"",
    "subcategory":''
  }
  constructor( private http: HttpClient) { }

  public postProduct (product){
    if (product.deal>0) {
      this.model.deal.active=true;
      this.model.deal.offer = product.deal.toString() + '%'
    }
    this.model.description= product.description;
    this.model.fakePrice= product.fakePrice;
    this.model.price= product.price;
    this.model.image=product.image;
    this.model.sk=product.reference;
    this.model.hk= product.title;
    this.model.category=product.category;
    this.model.status=product.status;
    this.model.details=product.details;
    return this.http.post(this.url+'productos',this.model, this.httpOptions)
  }

  public getVerify(){
  
  }


  public getDescriptions (){
    return this.http.get(this.url+'descripciones',  this.httpOptions)
  }

  public getProducts(){
    return this.http.post(this.url+'productos/all', {}, this.httpOptions)
  }

  public searchProducts(sk){
    return this.http.get(`${this.url}productos/search?sk=${sk}`,  this.httpOptions)
  }

  public activateProduct(product){
    return this.http.patch(`${this.url}productos/activar`, product ,this.httpOptions)
  }
  
  public pathProducts(product,reference){
    if (product.deal>0) {
      this.model.deal.active=true;
      this.model.deal.offer = product.deal.toString() + '%'
    }
    this.model.description= product.description;
    this.model.fakePrice= product.fakePrice;
    this.model.price= product.price;
    this.model.image=product.image;
    this.model.sk=reference;
    this.model.hk=product.title;
    // this.model.title=product.title;
    this.model.category=product.category;
    this.model.status=product.status;
    this.model.details=product.details;
    this.model.subcategory=product.subcategory;

    return this.http.patch(this.url+'productos',this.model, this.httpOptions)
  }

}
