import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CategoriesService } from 'src/app/services/categories.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit {
  categoriesList: any = [];
  showSpinner: boolean = true;
  showSuccess: boolean = false;
  categoryGroup: FormGroup;
  constructor(
    private service: CategoriesService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.categoryGroup = formBuilder.group({
      category: ['', Validators.required],
    });
    this.initCateogory();
  }

  initCateogory() {
    this.service.getCategory().subscribe((res) => {
      this.showSpinner = false;
      this.categoriesList = res['body']['body'];
    });
  }

  ngOnInit(): void {}

  route(category) {
    this.router.navigate(['dashboard/categorias', category, 'edit']);
  }

  newCategory() {
    if (this.categoryGroup.valid) {
      this.showSpinner = true;
      let item = {
        isCategory: true,
        hiden: true,
        isNew: true,
        category: this.categoryGroup.controls.category.value,
      };
      this.service.postCategory(item).subscribe((res) => {
        this.showSuccess = res['body']['success'];
        this.showSpinner = false;
        this.initCateogory();
        this.hideSuccess();
        this.categoryGroup.reset()
      });
    }
  }

  hideSuccess() {
    setTimeout(() => {
      this.showSuccess = false;
    }, 3000);
  }
}
