import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ServiciosService {
  private url = environment.url;
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apikey,
    }),
    observe: 'response' as 'response',
  };

  constructor(private http: HttpClient) {}

  public getServices() {
    return this.http.get(this.url + 'services', this.httpOptions);
  }

  public postService(item) {
    return this.http.post(this.url + 'services', item, this.httpOptions);
  }
}
