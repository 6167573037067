<div class="container" *ngIf="!showSpinner">
  <div class="row">
    <div class="col">
      <div class="alert alert-success" role="alert" *ngIf="showSuccess">
        Campaña modificada con éxito!
      </div>
      <div class="accordion" id="accordionExample">
        <div class="card">
          <div class="card-header" id="headingOne">
            <h5 class="mb-0">
              <button
                class="btn btn-light"
                type="button"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Banner principal
              </button>
            </h5>
          </div>
          <div
            id="collapseOne"
            class="collapse show"
            aria-labelledby="headingOne"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <div class="custom-file w-50 m-2">
                <input
                  type="file"
                  class="custom-file-input"
                  id="customFileLang"
                  lang="es"
                  alife-file-to-base64
                  multiple
                  (onFileChanged)="onFileChanges($event, 'banner')"
                  accept="image/*"
                />
                <label class="custom-file-label cursor-pointer" for="customFile"
                  >Cargar fotos</label
                >
                <span
                  class="badge badge-secondary mt-2 cursor-pointer"
                  (click)="save('banner')"
                  >Subir banner</span
                >
              </div>
              <div class="card-columns">
                <div
                  class="card mt-5"
                  style="margin: 10px"
                  *ngFor="let images of this.bannersList"
                >
                  <img src="{{ images.url }}" class="card-img-top" alt="..." />
                  <div class="card-body">
                    <form class="w-50">
                      <div class="row">
                        <div class="col-6 mt-4 ml-4">
                          <div
                            class="form-check"
                            *ngIf="this.bannersList.length > 1"
                          >
                            <input
                              type="checkbox"
                              class="form-check-input"
                              id="exampleCheck1"
                              [checked]="images.mobile"
                              (change)="mobileVersionChange(images, $event)"
                            />
                            <label class="form-check-label" for="exampleCheck1"
                              >Es versión mobile</label
                            >
                          </div>
                        </div>
                        <div class="col mt-4 ml-4">
                          <div
                            class="form-check"
                            *ngIf="this.bannersList.length > 1"
                          >
                            <input
                              type="checkbox"
                              class="form-check-input"
                              id="exampleCheck1"
                              [checked]="images.active"
                              (change)="bannerActiveChange(images, $event)"
                            />
                            <label class="form-check-label" for="exampleCheck1"
                              >Activo</label
                            >
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingThree">
            <h5 class="mb-0">
              <button
                class="btn btn-light collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Publicidad
              </button>
            </h5>
          </div>
          <div
            id="collapseTwo"
            class="collapse"
            aria-labelledby="headingThree"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <div class="custom-file w-50 m-2">
                <input
                  type="file"
                  class="custom-file-input"
                  id="customFileLang"
                  lang="es"
                  alife-file-to-base64
                  multiple
                  (onFileChanged)="onFileChanges($event, 'publicidad')"
                  accept="image/*"
                />
                <label class="custom-file-label cursor-pointer" for="customFile"
                  >Cargar fotos</label
                >
                <span
                  class="badge badge-secondary mt-2 cursor-pointer"
                  (click)="save('publicidad')"
                  >Subir publicidad</span
                >
              </div>
              <div class="card-columns">
                <div
                  class="card mt-5"
                  style="margin: 10px"
                  *ngFor="let images of this.publicidadList"
                >
                  <img src="{{ images.url }}" class="card-img-top" alt="..." />
                  <div class="card-body">
                    <form class="w-50">
                      <div class="row">
                        <div class="col mt-4 ml-4">
                          <div class="form-check">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              id="exampleCheck1"
                              [checked]="images.active"
                              (change)="publicidadActiveChange(images, $event)"
                            />
                            <label class="form-check-label" for="exampleCheck1"
                              >Activo</label
                            >
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingThree">
            <h5 class="mb-0">
              <button
                class="btn btn-light collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Header
              </button>
            </h5>
          </div>
          <div
            id="collapseThree"
            class="collapse"
            aria-labelledby="headingThree"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <form class="w-100" [formGroup]="campaignGroup">
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Descripcion</label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        placeholder="Ingresa el texto a mostrar"
                        formControlName="paragraph"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <button
                      class="btn btn-light cursor-pointer m-3"
                      (click)="addHeader()"
                    >
                      Agregar
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <ul class="list-group">
                      <li
                        class="list-group-item"
                        *ngFor="let desc of this.headerList"
                      >
                        <div class="d-flex row">
                          <div class="col mt-3">
                            <span> {{ desc.paragraph }}</span>
                          </div>
                          <div class="col d-flex justify-content-end mt-4">
                            <div class="form-check">
                              <input
                                type="checkbox"
                                class="form-check-input"
                                id="exampleCheck1"
                                [checked]="desc.active"
                                (change)="headerActiveChange(desc, $event)"
                              />
                              <label
                                class="form-check-label"
                                for="exampleCheck1"
                                >Activo</label
                              >
                            </div>
                          </div>
                          <div class="col d-flex justify-content-end">
                            <button
                              class="btn btn-light cursor-pointer m-3"
                              (click)="removeHeader(desc)"
                            >
                              x
                            </button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingFour">
            <h5 class="mb-0">
              <button
                class="btn btn-light collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                Cupones
              </button>
            </h5>
          </div>
          <div
            id="collapseFour"
            class="collapse"
            aria-labelledby="headingFour"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <div class="container w-100">
                <div class="row">
                  <div class="col">
                    <form class="w-50" [formGroup]="cuponGroup">
                      <label for="exampleInputEmail1">Descripción</label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        placeholder="Nombre de la categoría"
                        formControlName="description"
                      />
                    </form>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <button
                      class="btn btn-light cursor-pointer m-3"
                      (click)="addCupon()"
                    >
                      Crear cupón
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <ul class="list-group">
                      <li
                        class="list-group-item"
                        *ngFor="let cupon of this.cuponsList"
                      >
                        <div class="d-flex row">
                          <div class="col mt-3">
                            <span> {{ cupon.description }}</span>
                          </div>
                          <div class="col d-flex justify-content-end mt-4">
                            <div class="form-check">
                              <input
                                type="checkbox"
                                class="form-check-input"
                                id="exampleCheck1"
                                [checked]="cupon.active"
                                (change)="cuponActiveChange(cupon, $event)"
                              />
                              <label
                                class="form-check-label"
                                for="exampleCheck1"
                                >Activo</label
                              >
                            </div>
                          </div>
                          <div class="col d-flex justify-content-end">
                            <button
                              class="btn btn-light cursor-pointer mt-3 mb-3"
                              (click)="route(cupon.description)"
                            >
                              ...
                            </button>
                            <button
                              class="btn btn-light cursor-pointer m-3"
                              (click)="removeCupon(cupon)"
                            >
                              x
                            </button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-spinner *ngIf="showSpinner"></app-spinner>
