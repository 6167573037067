import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {
  private url = environment.url
  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' ,
     'x-api-key': environment.apikey 
    }),
    observe: 'response' as 'response',
  };
  constructor(private http: HttpClient) { }
  
  public getTransactions (){
    return this.http.get(this.url+'transacciones',  this.httpOptions)
  }
  public changeTransactioNStatus (transaction){
    return this.http.patch(this.url+'transacciones/estados', transaction , this.httpOptions)
  }
  public searchTransactions(reference){
    return this.http.get(`${this.url}transacciones/search?reference=${reference}`,  this.httpOptions)
  }
  public confirmTransactionStatus(paymentInformation){
    return this.http.post(this.url+'pagos/confirmacion', paymentInformation, this.httpOptions);
  }
}


//reference