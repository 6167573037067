import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SignInInformation } from '../model/model.model';
import { SignInService } from '../services/sign-in.service';
import { errorHandler } from '../errors/errorHandler';
import { VariablesService } from '../services/variables.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public signInForm: FormGroup;
  public showAlert: boolean = false;
  public message: string = '';
  public signInInformation = {} as SignInInformation;

  constructor(
    private signInService: SignInService,
    private variablesServices: VariablesService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.signInForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      username: ['', Validators.required],
    });
  }

  public signIn() {
    this.signInInformation.username = this.signInForm.value.username;
    this.signInInformation.password = this.signInForm.value.password;
    this.signInService
      .signInAmplify(this.signInInformation)
      .then((user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.signInService
            .completeNewPasswordAmplify(user, this.signInInformation)
            .then((res) => {
              this.navigate();
            })
            .catch((err) => {
              this.showAlert = true;
              this.message = errorHandler(err);
            });
        } else {
          this.navigate();
        }
      })
      .catch((err) => {
        this.showAlert = true;
        this.message = errorHandler(err);
      });
  }
  public navigate() {
    this.router.navigate(['dashboard']);
    this.variablesServices.updateShowVariable(true);
  }
}
