import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CampaignService } from 'src/app/services/campaign.service';

@Component({
  selector: 'app-edit-cupon',
  templateUrl: './edit-cupon.component.html',
  styleUrls: ['./edit-cupon.component.scss'],
})
export class EditCuponComponent implements OnInit {
  showSpinner: boolean = true;
  showSuccess: boolean = false;
  cuponGroup: FormGroup;
  campaignGot: any = {};
  cuponGot: any = {};
  cuponsList: any = [];

  constructor(
    private service: CampaignService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.cuponGroup = formBuilder.group({
      active: [''],
      amount: [''],
      conditions: [''],
      description: [''],
      key: [''],
      kind: [''],
    });
    this.chargeCampaign();
  }

  ngOnInit(): void {}

  chargeCampaign() {
    const cupon = this.route.snapshot.paramMap.get('cupon');
    this.service.getCampaign().subscribe((res) => {
      this.showSpinner = false;
      this.campaignGot = res['body']['body'];
      if (res['body']['body']['cupons']) {
        this.cuponsList = res['body']['body']['cupons'];
        this.cuponGot = res['body']['body']['cupons'].find(
          (item) => item.description === cupon
        );
      }

      this.initForm(this.cuponGot);
    });
  }

  initForm(cupon) {
    this.cuponGroup = this.formBuilder.group({
      active: [cupon.active],
      amount: [cupon.amount],
      description: [cupon.description],
      key: [cupon.key],
      kind: [cupon.kind],
      conditions: [cupon.conditions],
    });
  }

  save() {
    this.showSpinner = true;
    this.cuponsList = this.cuponsList.filter(
      (item) => item['description'] !== this.cuponGot.description
    );
    this.cuponsList.push({
      active: this.cuponGroup.controls['active'].value,
      amount: this.cuponGroup.controls['amount'].value,
      conditions: this.cuponGroup.controls['conditions'].value,
      description: this.cuponGroup.controls['description'].value,
      key: this.cuponGroup.controls['key'].value,
      kind: this.cuponGroup.controls['kind'].value,
    });
    let item = {
      isCupon: true,
      cupons: [...this.cuponsList],
    };
    this.service.postCampaign(item).subscribe((res) => {
      this.showSuccess = res['body']['success'];
      this.chargeCampaign();
      this.showSpinner = false;
      this.hideSuccess();
    });
  }

  hideSuccess() {
    setTimeout(() => {
      this.showSuccess = false;
    }, 3000);
  }
}
