import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { ProductService } from '../../services/product.service';
import { Subscription } from 'rxjs';
import { VariablesService } from '../../services/variables.service';
import { CategoriesService } from 'src/app/services/categories.service';

@Component({
  selector: 'app-add-products',
  templateUrl: './add-products.component.html',
  styleUrls: ['./add-products.component.scss'],
})
export class AddProductsComponent implements OnInit {
  public fileToUpload: File = null;
  public product: FormGroup;
  public imagesList: any = [];
  public categoryList: any = [];
  public xmascategoryList: any = [];
  public subcategoryListAux: any = [];

  public subCategoryList: any[];
  public disableToggle: boolean = false;
  public productSubscription: Subscription;
  public success: boolean = false;
  public showAlert: boolean = false;
  public message: string = '';
  public productSelected: any = {};
  public isEdit: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private service: ProductService,
    private variableService: VariablesService,

  ) {
    this.service.getDescriptions().subscribe((response) => {
      this.categoryList = response['body']['body']['category'];
      this.subCategoryList = response['body']['body']['subCategory'];
      this.xmascategoryList = response['body']['body']['xmasCategory'];
    });

    this.productSubscription = this.variableService.product.subscribe(
      (product) => {
        if (product.sk !== '') {
          this.initFormEdit(product);
          this.startListening();
        } else {
          this.initForm();
        }
        return product;
      }
    );
  }
  ngOnInit(): void {
    this.success = false;
    this.message = '';
  }

  public startListening() {
    this.product.get('deal').valueChanges.subscribe((discount) => {
      if (
        !Number.isNaN(discount) &&
        discount >= 0 &&
        discount <= 100 &&
        this.product.value['fakePrice'] > 0
      ) {
        this.product.patchValue({
          price:
            this.product.value['fakePrice'] -
            (discount * this.product.value['fakePrice']) / 100,
        });
      }
    });
    this.product.get('fakePrice').valueChanges.subscribe((fakePrice) => {
      if (
        !Number.isNaN(fakePrice) &&
        this.product.value['deal'] >= 0 &&
        this.product.value['deal'] <= 100 &&
        this.product.value['fakePrice'] > 0
      ) {
        this.product.patchValue({
          price:
            this.product.value['fakePrice'] -
            (this.product.value['deal'] * this.product.value['fakePrice']) /
              100,
        });
      }
    });
  }

  public resetForm() {
    this.imagesList = [];
    this.product.reset();
    this.startListening();
  }
  public initForm() {
    this.success = false;
    this.message = '';
    this.imagesList = [];

    this.product = this.formBuilder.group({
      deal: [0],
      description: ['', Validators.required],
      image: ['', Validators.required],
      fakePrice: [0, Validators.required],
      price: [0, Validators.required],
      reference: ['', Validators.required],
      details: ['', Validators.required],
      generateReference: [false],
      title: ['', Validators.required],
      subcategory: ['', Validators.required],

      category: ['', Validators.required],
      status: ['', Validators.required],
    });
    this.startListening();
  }

  public initFormEdit(product) {
    console.log(product);
    this.productSelected = product;
    this.success = false;
    this.message = '';
    this.isEdit = true;
    let offer = 0;
    this.imagesList = product.image;
    if (product.deal.active) {
      offer = parseInt(product.deal.offer.split('%')[0]);
    }
    this.product = this.formBuilder.group({
      deal: [offer],
      description: [product.description, Validators.required],
      image: [product.image, Validators.required],
      fakePrice: [product.fakePrice, Validators.required],
      price: [product.price, Validators.required],
      reference: [{ value: product.sk, disabled: true }, Validators.required],
      details: [product.details, Validators.required],
      generateReference: [true],
      title: [product.hk, Validators.required],
      category: [product.category, Validators.required],
      subcategory: [product.subcategory, Validators.required],

      status: [product.status, Validators.required],
    });
  }

  public onFileChanges(file) {
    this.imagesList.push(file[0]);
    this.product.patchValue({ image: this.imagesList });
  }

  public changeProductStatus() {
    this.showAlert = false;
    this.success = false;
    this.disableToggle = true;
    if (this.productSelected) {
      switch (this.productSelected.status) {
        case 'Activo':
          this.productSelected.status = 'Inactivo';
          this.product.patchValue({ status: 'Inactivo' });
          break;
        case 'Inactivo':
          this.productSelected.status = 'Activo';
          this.product.patchValue({ status: 'Activo' });
          break;
        default:
          break;
      }
      this.service
        .activateProduct(this.productSelected)
        .subscribe((response) => {
          this.disableToggle = false;

          if (response['body']['success']) {
            this.success = true;
            this.message = response['body']['message'];
          } else {
            this.showAlert = true;
            this.message = response['body']['message'];
          }
        });
    }
  }

  public onSubmit() {
    this.showAlert = false;
    this.success = false;
    if (this.isEdit) {
      console.log(this.product.value);
     /*  this.service
        .pathProducts(this.product.value, this.product.controls.reference.value)
        .subscribe((response) => {
          if (response['body']['success']) {
            this.success = true;
            this.message = response['body']['message'];
          } else {
            this.showAlert = true;
            this.message = response['body']['message'];
          }
        }); */
    } else {
      /* this.service.postProduct(this.product.value).subscribe((response) => {
        this.success = true;
        this.message = 'Producto guardado con éxito!';
        this.resetForm();
      }); */
    }
  }

  public generateReference() {
    if (this.product.value['generateReference']) {
      this.product.patchValue({ generateReference: false });
      this.product.patchValue({ reference: '' });
    } else {
      this.product.patchValue({ generateReference: true });
      this.product.patchValue({ reference: uuidv4().split('-')[0] });
    }
  }

  public deleteImage(image) {
    this.imagesList.splice(this.imagesList.indexOf(image), 1);
    this.product.patchValue({ image: this.imagesList });
  }
}
