<div class="container" *ngIf="!showSpinner">
  <div class="row">
    <div class="col">
      <div class="alert alert-success" role="alert" *ngIf="showSuccess">
        Servicio modificado con éxito!
      </div>
      <div class="accordion" id="accordionExample">
        <div class="card">
          <div class="card-header" id="headingOne">
            <h5 class="mb-0">
              <button
                class="btn btn-light"
                type="button"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Información general
              </button>
            </h5>
          </div>

          <div
            id="collapseOne"
            class="collapse show"
            aria-labelledby="headingOne"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <form class="w-100" [formGroup]="serviceGroup">
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Título</label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        placeholder="Nombre del servicio"
                        disabled
                        formControlName="title"
                      />
                    </div>
                  </div>
                  <div class="col mt-4 ml-4">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck1"
                        formControlName="active"
                      />
                      <label class="form-check-label" for="exampleCheck1"
                        >Activo</label
                      >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Descripción</label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        placeholder="Descripción del servicio"
                        formControlName="description"
                      />
                    </div>
                  </div>
                </div>
                <button
                  class="btn btn-light cursor-pointer m-3"
                  (click)="save()"
                >
                  Guardar
                </button>
                <div class="row w-100">
                  <div class="col w-100">
                    <div class="custom-file w-25 m-2">
                      <input
                        type="file"
                        class="custom-file-input"
                        id="customFileLang"
                        lang="es"
                        alife-file-to-base64
                        multiple
                        (onFileChanged)="onFileChanges($event)"
                        accept="image/*"
                      />
                      <label
                        class="custom-file-label cursor-pointer"
                        for="customFile"
                        >Cargar foto</label
                      >
                    </div>
                    <div class="card-columns">
                      <div class="card mt-5" style="margin: 10px">
                        <img
                          src="{{ this.serviceGot.url }}"
                          class="card-img-top"
                          alt="..."
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingThree">
            <h5 class="mb-0">
              <button
                class="btn btn-light collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Módulos
              </button>
            </h5>
          </div>
          <div
            id="collapseThree"
            class="collapse"
            aria-labelledby="headingThree"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <form class="w-50" [formGroup]="moduleGroup">
                    <label for="exampleInputEmail1">Nombre</label>
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      placeholder="Nombre del módulo"
                      formControlName="module"
                    />
                  </form>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <button
                    class="btn btn-light cursor-pointer m-3"
                    (click)="newModule()"
                  >
                    Guardar módulo
                  </button>
                </div>
              </div>
              <table class="table table-bordered w-50">
                <tbody>
                  <tr *ngFor="let module of this.moduleList">
                    <td>
                      <div class="d-flex row">
                        <div class="col mt-2">
                          <span>{{ module.title }}</span>
                        </div>
                        <div class="col d-flex justify-content-end">
                          <button
                            class="btn btn-light"
                            (click)="redirect(module.title)"
                          >
                            ...
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <button class="btn btn-light cursor-pointer m-3" (click)="save()">
        Guardar
      </button>
    </div>
  </div>
</div>
<app-spinner *ngIf="showSpinner"></app-spinner>
