import { NOT_AUTHORIZED_ERROR, NEW_PASSWORD_REQUIRED, GENERIC_ERROR } from '../errors/errors';

export function errorHandler(err){
    if (err.code==="NotAuthorizedException") {
        return NOT_AUTHORIZED_ERROR;
    }else{
        return GENERIC_ERROR;
    }
}

export function messageHandler(message){
    if (message==="NEW_PASSWORD_REQUIRED") {
        return NEW_PASSWORD_REQUIRED;
    }
}