import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiciosService } from 'src/app/services/servicios.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent implements OnInit {
  servicesList: any = [];

  showSpinner: boolean = true;
  showSuccess: boolean = false;
  serviceGroup: FormGroup;

  constructor(
    private service: ServiciosService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.serviceGroup = formBuilder.group({
      service: [''],
    });
    this.initServices();
  }

  initServices(){
    this.service.getServices().subscribe((res) => {
      this.showSpinner = false;
      this.servicesList = res['body']['body']['services'];
    });
  }

  ngOnInit(): void {}

  route(service) {
    this.router.navigate(['dashboard/servicios', service, 'edit']);
  }

  newService() {
    this.showSpinner = true;
    let item = {
      isNew: true,
      services: {
        active: false,
        title: this.serviceGroup.controls.service.value,
      },
    };
    this.service.postService(item).subscribe((res) => {
      this.showSuccess = res['body']['success'];
      this.showSpinner = false;
      this.hideSuccess();
      this.initServices();
    });
  }

  hideSuccess() {
    setTimeout(() => {
      this.showSuccess = false;
    }, 3000);
  }
}
/**
 * {
  "isNew": false,
  "active": true,
  "description": "",
  "modules": [
    {
      "active": true,
      "description": "hey",
      "title": "",
      "url": "https://s3.amazonaws.com/dev.decorazzia.images/banners/navidad.png"
    },
    {
      "active": true,
      "description": "dhey",
      "title": "",
      "url": "https://s3.amazonaws.com/dev.decorazzia.images/banners/navidad.png"
    }
  ],
  "title": "1",
  "url": "https://s3.amazonaws.com/dev.decorazzia.images/banners/navidad.png"
}
 * 
 */
