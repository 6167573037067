import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  private url = environment.url;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apikey,
    }),
    observe: 'response' as 'response',
  };

  constructor(private http: HttpClient) {}

  public getCategory() {
    return this.http.get(this.url + 'categorias', this.httpOptions);
  }

  public postCategory(item) {
    return this.http.post(this.url + 'categorias', item, this.httpOptions);
  }
}
