import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {productConst} from '../constants/constants'
@Injectable({
  providedIn: 'root'
})
export class VariablesService {
  private productSource = new BehaviorSubject<any>(productConst)
  public  product = this.productSource.asObservable();


  private showWelcomeSource = new BehaviorSubject<boolean>(false)
  public  show = this.showWelcomeSource.asObservable();


  constructor() { }

  public updateProductVariable(productService){  
    this.productSource.next(productService);    
  }

  public updateShowVariable(showService){  
    this.showWelcomeSource.next(showService);    
  }

}
