import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TransactionsService } from 'src/app/services/transactions.service';

@Component({
  selector: 'app-sales-detail',
  templateUrl: './sales-detail.component.html',
  styleUrls: ['./sales-detail.component.scss']
})
export class SalesDetailComponent implements OnInit {
  reference: any ="";
  constructor(
    private transaction: TransactionsService,
    private route: ActivatedRoute

  ) {
    this.reference =  this.route.snapshot.paramMap.get('referencia');
    this.transaction
      .searchTransactions(this.reference)
      .subscribe((response) => {
        console.log(response);
        if (response['body']['success']) {
          console.log(response['body']['body']);
        } 
      });
  }

  ngOnInit(): void {
  }

}
