import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { AlifeFileToBase64Module } from 'alife-file-to-base64';
import { AddProductsComponent } from './add-products/add-products.component';
import { ListProductsComponent } from './list-products/list-products.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SalesComponent } from './sales/sales.component';
import { CategoriesComponent } from './categories/categories.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { EditCategoriesComponent } from './edit-categories/edit-categories.component';
import { SalesDashboardComponent } from './sales-dashboard/sales-dashboard.component';
import { EditSubcategoryComponent } from './edit-subcategory/edit-subcategory.component';
import { SalesDetailComponent } from './sales-detail/sales-detail.component';
import { AdminCampaignComponent } from './admin-campaign/admin-campaign.component';
import { ServicesComponent } from './services/services.component';
import { EditProductComponent } from './edit-product/edit-product.component';
import { EditServiceComponent } from './edit-service/edit-service.component';
import { EditServiceModuleComponent } from './edit-service-module/edit-service-module.component';
import { EditCuponComponent } from './edit-cupon/edit-cupon.component';

export const dashboardRoutes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      { path: 'agregar', component: AddProductsComponent },
      { path: 'productos', component: ListProductsComponent },
      { path: 'productos/:product/edit', component: EditProductComponent },
      { path: 'ventas', component: SalesDashboardComponent },
      { path: 'ventas/:referencia/detail', component: SalesDetailComponent },
      { path: 'administrar', component: AdminCampaignComponent },
      { path: 'administrar/:cupon/edit', component: EditCuponComponent },
      { path: 'categorias', component: CategoriesComponent },
      { path: 'categorias/:categoria/edit', component: EditCategoriesComponent },
      { path: 'sub-categorias/:categoria/:subcategoria/edit', component: EditSubcategoryComponent },
      { path: 'servicios', component: ServicesComponent },
      { path: 'servicios/:servicio/edit', component: EditServiceComponent },
      { path: 'servicios/:servicio/:module/edit', component: EditServiceModuleComponent },

    ],
  },
];

@NgModule({
  declarations: [
    AddProductsComponent,
    ListProductsComponent,
    CategoriesComponent,
    SpinnerComponent,
    EditCategoriesComponent,
    SalesDashboardComponent,
    EditSubcategoryComponent,
    SalesDetailComponent,
    AdminCampaignComponent,
    ServicesComponent,
    EditProductComponent,
    EditServiceComponent,
    EditServiceModuleComponent,
    EditCuponComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(dashboardRoutes),
    FormsModule,
    ReactiveFormsModule,
    AlifeFileToBase64Module,
  ],
  exports: [RouterModule],
})
export class DashboardModule {}
