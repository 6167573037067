import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiciosService } from 'src/app/services/servicios.service';

@Component({
  selector: 'app-edit-service',
  templateUrl: './edit-service.component.html',
  styleUrls: ['./edit-service.component.scss'],
})
export class EditServiceComponent implements OnInit {
  showSpinner: boolean = true;
  serviceGot: any = {};
  moduleList: any[] = [];
  fileToUpload: File = null;
  showSuccess: boolean = false;
  serviceGroup: FormGroup;
  moduleGroup: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private service: ServiciosService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.serviceGroup = formBuilder.group({
      active: [''],
      description: [''],
      title: [''],
      url: [''],
    });

    this.moduleGroup = formBuilder.group({
      module: [''],
    });

    this.changeService();
  }

  changeService() {
    this.service.getServices().subscribe((res) => {
      this.showSpinner = false;
      const service = this.route.snapshot.paramMap.get('servicio');
      this.serviceGot = res['body']['body']['services'].find(
        (item) => item['title'] === service
      );
      if (this.serviceGot['modules'])
        this.moduleList = this.serviceGot['modules'];
      console.log(this.moduleList);

      this.initForm(this.serviceGot);
    });
  }

  initForm(service) {
    this.serviceGroup = this.formBuilder.group({
      active: [service.active],
      description: [service.description],
      title: [service.title],
      url: [service.url],
    });
  }

  onFileChanges(file) {
    this.serviceGot.url = file[0];
    this.save();
  }

  ngOnInit(): void {}

  save() {
    this.showSpinner = true;
    const serviceToSave = {
      isNew: false,
      active: this.serviceGroup.controls['active'].value,
      description: this.serviceGroup.controls['description'].value,
      modules: this.moduleList,
      title: this.serviceGroup.controls['title'].value,
      url: this.serviceGot.url,
    };

    this.service.postService(serviceToSave).subscribe((res) => {
      this.showSuccess = res['body']['success'];
      this.changeService();
      this.showSpinner = false;
      this.hideSuccess();
    });
  }

  hideSuccess() {
    setTimeout(() => {
      this.showSuccess = false;
    }, 3000);
  }

  redirect(module) {
    this.router.navigate([
      'dashboard/servicios',
      this.serviceGot.title,
      module,
      'edit',
    ]);
  }

  newModule() {
    this.showSpinner = true;
    const serviceToSave = {
      isNew: false,
      active: this.serviceGroup.controls['active'].value,
      description: this.serviceGroup.controls['description'].value,
      modules: [
        ...this.moduleList,
        {
          active: false,
          description: '',
          title: this.moduleGroup.controls['module'].value,
          url: '',
        },
      ],
      title: this.serviceGroup.controls['title'].value,
      url: this.serviceGot.url,
    };
    this.service.postService(serviceToSave).subscribe((res) => {
      this.showSuccess = res['body']['success'];
      this.showSpinner = false;
      this.hideSuccess();
      this.changeService();
    });
  }
}

/**
 * 
 * {
  "isNew": false,
  "active": true,
  "description": "",
  "modules": [
    {
      "active": true,
      "description": "hey",
      "title": "",
      "url": "https://s3.amazonaws.com/dev.decorazzia.images/banners/navidad.png"
    },
    {
      "active": true,
      "description": "dhey",
      "title": "",
      "url": "https://s3.amazonaws.com/dev.decorazzia.images/banners/navidad.png"
    }
  ],
  "title": "1",
  "url": "https://s3.amazonaws.com/dev.decorazzia.images/banners/navidad.png"
}
*/
